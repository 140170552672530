import React, { useState } from 'react';
import WorksCard from './WorksCard';
import './Works.css';
import Sphere from './Sphere';
import TopRightSphere from './TopRightSphere';
import BottomRightSphere from './BottomRightSphere';
import TopLeftSphere from './TopLeftSphere';
import CenterBottomSphere from './CenterBottomSphere';

function WorksCardsSection() {
  const [cards, setCards] = useState([<WorksCard key={0} />]);

  const addCard = () => {
    const newCard = <WorksCard key={cards.length} />;
    setCards([...cards, newCard]);
  };

  return (
    <div className='d-grid container justify-content-center w-100 p-0 container-card-works'>
      {cards.map((card, index) => (
        <div className='w-100 container p-0' key={index}>{card}</div>
      ))}
      {/* Spheres placed outside the cards' grid */}
      <div className='spheres-container d-flex justify-content-center w-100 p-0'>
        <Sphere />
        <TopRightSphere />
        <BottomRightSphere />
        <TopLeftSphere />
        <CenterBottomSphere />
      </div>
    </div>
  );
}

export default WorksCardsSection;
