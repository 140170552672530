import React from "react";
import './Contact.css';

const Contact = () => {
    return (
      <div id="section3" className='container justify-content-center p-0 container-contact d-flex w-100'>
        <div className='row w-100 d-flex justify-content-between align-items-center'>
            <div className='col-lg-6 contact_col'>
                <h2>let's <br></br>Co<span>nn</span>ect</h2>
               <p>looking to a work togethe? i'm always interested in learning about new sites, projects, consulting on a processes and sistem, or even just making a <span>connection</span>.</p>
            </div>
            <div className='col-lg-5 col-voices'>
               <div className="footer-voice">
               <h3>Email</h3>
                <a href="mailto:guglielmodl67@gmail.com"><p>guglielmodl67gmail.com</p></a>
               </div>
               <div className="footer-voice">
               <h3>Instagram</h3>
                <a href="https://www.instagram.com/guglielmodl/" target="_blank" rel="noopener noreferrer"><p>guglielmodl</p></a>
               </div>
               <div className="footer-voice">
               <h3>linkedin</h3>
                <a href="www.linkedin.com/in/guglielmo-de-lise-701155204" target="_blank" rel="noopener noreferrer" ><p>guglielmo de lise</p></a>
               </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default Contact;