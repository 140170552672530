// Preload.js
import React, { useEffect, useState } from 'react';
import './Preload.css';

const Preload = ({ loadingText, visible, visibilityDuration }) => {
  const [showPreload, setShowPreload] = useState(true);
  const [counterText, setCounterText] = useState('0%');

  useEffect(() => {
    let visibilityTimer;
    let counter = 0;
    const animationDuration = 900; // 1 secondo

    const updateCounter = () => {
      if (counter <= 100) {
        setCounterText(`${counter}%`);
        counter += 1;
        setTimeout(updateCounter, animationDuration / 100); // Aggiorna ogni 10 millisecondi
      }
    };

    updateCounter(); // Avvia il contatore al render del componente

    if (visible) {
      visibilityTimer = setTimeout(() => {
        setShowPreload(false);
      }, visibilityDuration);
    }

    return () => {
      clearTimeout(visibilityTimer);
    };
  }, [visible, visibilityDuration]);

  return (
    <div id="preload-container" style={{ visibility: showPreload ? 'visible' : 'hidden' }}>
      <div id="loading-text">{loadingText}</div>
      <div id="loading-progress">{counterText}</div>
      <div id="loading-dots">
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
      </div>
    </div>
  );
};

export default Preload;
