// ParallaxTitle.js
import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Planet from '../src/img-sito/planet-jupter.gif';
import arrowDownSVG from '../src/img-sito/arrow-down-1.svg';
import './ParallaxTitle.css';

const ParallaxTitle = () => {
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    const getLocalTime = () => {
      const options = {
        timeZone: 'Europe/Rome', // Fuso orario dell'Italia
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Rome' });

      setLocalTime(formatter.format(new Date(currentTime)));
    };

    const intervalId = setInterval(getLocalTime, 1000);

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Parallax strength={500}>
     
      <div className="container container-home-header d-flex justify-content-center">
        <div className="row w-100 justify-content-center align-items-center">
          <div className="col-lg-6 text-center img_planet">
          <img src={Planet} alt="Planet" />
          </div>
          <div className='col-lg-6 text-center title-home'>
          <h1 className="parallax-title">
              <span className=" codify">Codify</span> <br />
              <span className=" your"> - Your</span> <br />
              <span className="creativity">Creativity</span>
            </h1>
          </div>
        </div>
      </div>
      <div className='container p-0 justify-content-center d-flex'>
        <div className='row w-100 justify-content-between d-flex align-items-center content-bottom-hero '>
          <div className='col-lg-4 col-6 p-0'>
            <p>
              Currently Available <span>For</span> <br />
              Freelance Worldwide
            </p>
          </div>
          <div className='col-lg-4 d-none d-lg-block text-center arrow-scroll z-2'>
          <img src={arrowDownSVG} alt="Arrown down" />
          </div>
          <div className='col-lg-4 col-6 hour-country text-end p-0'>
            <p>my local time <br></br>{localTime} (GMT+1)</p>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default ParallaxTitle;
