import React from 'react';
import Ciccone from '../src/img-sito/ciccone.jpg';
import AdventureTeam from '../src/img-sito/adventure-team.jpg';
import NekoBot from '../src/img-sito/botneko.webp';
import Raf from '../src/img-sito/Frame 56.png';
import BH from '../src/img-sito/bh.jpg';
import './Works.css';

function WorksCard() {
  return (
    <div className='container d-flex justify-content-center w-100 p-0'>
      <div className='row w-100'>

        {/* Adventure Team Card */}
        <div className="col-12 d-flex p-0 justify-content-center w-cards">
          <a href="https://adventure-team.it/" target="_blank" rel="noopener noreferrer" className='row w-100 d-flex align-items-end pb-lg-4 pt-lg-4 px-lg-2 border-top padding-card-mobile'>
            <div className='col-8 p-0'>
              <h2 className='m-0'>Adventure team</h2>
              <p className='m-0'>wordpress</p>
            </div>
            <div className='col-4 text-end'>
              <img src={AdventureTeam} className='w-75' alt="Adventure Team" />
            </div>
          </a>
        </div>
        {/* Blowhammer Card */}
        <div className="col-12 d-flex p-0 justify-content-center w-cards">
          <a href="https://it.blowhammer.com/" target="_blank" rel="noopener noreferrer" className='row w-100 d-flex align-items-end pb-lg-4 pt-lg-4 px-lg-2 border-top padding-card-mobile'>
            <div className='col-8 p-0'>
              <h2 className='m-0'>Blowhammer</h2>
              <p className='m-0'>e-commerce / liquid / js</p>
            </div>
            <div className='col-4 text-end'>
              <img src={BH} className='w-75' alt="Adventure Team" />
            </div>
          </a>
        </div>

        {/* NekoBot Card */}
        <div className="col-12 d-flex p-0 justify-content-center w-cards">
          <a href="#" className='row w-100 d-flex align-items-end pb-lg-4 pt-lg-4 px-lg-2 border-top padding-card-mobile'>
            <div className='col-8 p-0'>
              <h2 className='m-0'>NekoBot</h2>
              <p className='m-0'>Python</p>
            </div>
            <div className='col-4 text-end'>
              <img src={NekoBot} className='w-75' alt="NekoBot" />
            </div>
          </a>
        </div>
        {/* ARaf4 Card */}
        <div className="col-12 d-flex p-0 justify-content-center w-cards">
          <a href="https://raf-4.com/" target="_blank" rel="noopener noreferrer" className='row w-100 d-flex align-items-end pb-lg-4 pt-lg-4 px-lg-2 border-top padding-card-mobile'>
            <div className='col-8 p-0'>
              <h2 className='m-0'>Raf4</h2>
              <p className='m-0'>wordpress</p>
            </div>
            <div className='col-4 text-end'>
              <img src={Raf} className='w-75' alt="Raf 4 Brand" />
            </div>
          </a>
        </div>

      </div>
    </div>
  );
}

export default WorksCard;
