import React from 'react';
import './HomeAbout.css';
import MyCards from './MyCards'; 
import IconContact from '../src/img-sito/icon-contact.svg';

const HomeAbout = () => {
    return (
      <div id='section1' className='container container-home-about d-flex justify-content-center p-0'>
        <div className='row w-100 d-flex justify-content-between align-items-start'>
            <div className='col-12 col-lg-5 about-col'>
                <h2>About <span>me</span></h2>
                <h3>-Frontend Devevolper</h3>
                <p>
                I’m Guglielmo De Lise, I create <span>unusual</span> but visually and aesthetically functional interfaces for mobile & Web
                </p>
                
            </div>
            <div className='col-12 col-lg-7 skills'>
            <h2>skills</h2>
            <div className='row cards-skills justify-content-between d-flex m-0'>
              <div className='language col-lg-3 skills-col'>
                <h3>languages</h3>
                <p>HTML / CSS / SCSS / JS / PYTHON / LIQUID</p>
              </div>
              <div className='frameworks col-lg-3 skills-col'>
              <h3>frameworks</h3>
                <p>BOOTSTRAP / REACT / ANGULAR / VUE</p>
              </div>
              <div className='tools col-lg-3 skills-col'>
              <h3>TOOLS</h3>
                <p>VSCODE / FIGMA / GIT / LINUX / FONT AWESOME / GOOGLE FONT</p>
              </div>
            </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default HomeAbout;