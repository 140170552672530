import React, { useEffect, useState } from 'react';
import './App.css'; // Assuming you extract the CSS into a separate file

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const updatePosition = (e) => {
      setPosition({ x: e.clientX - 10, y: e.clientY - 10 });
    };

    const handleClick = () => {
      setExpanded(true);
      setTimeout(() => {
        setExpanded(false);
      }, 500);
    };

    window.addEventListener('mousemove', updatePosition);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('mousemove', updatePosition);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div
      className={`cursor ${expanded ? 'expand' : ''}`}
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    ></div>
  );
};

export default Cursor;
