import React, { useRef, useEffect } from 'react';
import './marquee.css'; // Assicurati che questo percorso sia corretto

const InfiniteSlider = () => {
  const elRef = useRef(null); // useRef hook per riferirsi all'elemento DOM

  useEffect(() => {
    const el = elRef.current;
    let elWidth = el.offsetWidth;
    let windowWidth = window.innerWidth;
    let mouseX = 0;
    let prevMouseX = 0;
    let skewTarget = 0;
    let translateTarget = 0;
    let skewWithEasing = 0;
    let translateWithEasing = 0;
    let skewEasingFactor = 0.1;
    let translateEasingFactor = 0.05;

    const handleMouseMove = (e) => {
      mouseX = e.pageX;
    };

    const handleWindowResize = () => {
      elWidth = el.offsetWidth;
      windowWidth = window.innerWidth;
    };

    const lerp = (start, end, factor) => {
      return (1 - factor) * start + factor * end;
    };

    const animateMe = () => {
      skewTarget = mouseX - prevMouseX;
      prevMouseX = mouseX;
      translateTarget = (elWidth - windowWidth) / windowWidth * mouseX * -1;
      skewWithEasing = lerp(skewWithEasing, skewTarget, skewEasingFactor);
      skewWithEasing = Math.min(Math.max(parseInt(skewWithEasing), -75), 75);
      translateWithEasing = lerp(translateWithEasing, translateTarget, translateEasingFactor);

      el.style.transform = `translateX(${translateWithEasing}px) skewX(${skewWithEasing}deg)`;

      window.requestAnimationFrame(animateMe);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleWindowResize);
    window.requestAnimationFrame(animateMe);

    // Cleanup function
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // L'array vuoto indica che useEffect verrà eseguito solo una volta, al montaggio del componente

  return (
    <div className='container p-0 d-flex justify-content-center'>
      <div className='row w-100 p-0 m-0'>
        <div className='col-12 col-infinity'>
          <section className="container-infinite-text">
            <p ref={elRef} className="title">The pixel perfect artisan 💡 think - create</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default InfiniteSlider;
