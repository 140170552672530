import React from "react";
import './Works.css';
import ArrowDown from '../src/img-sito/ArrowDown.svg';

const Works = () => {
    return (
      <div id="section2" className='container container-works justify-content-center d-flex w-100'>
        <div className='row w-100 d-flex justify-content-between align-items-center'>
            <div className='col-12 p-0'>
            <div class="box">
              <h1>feat works</h1>
              <h1>feat works</h1>
            </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default Works;