// App.js
import React, { useState, useEffect } from 'react';
import Preload from './Preload';
import Cursor from './Cursor';  // or the correct path
import ParallaxTitle from './ParallaxTitle';
import Navbar from './Navbar';
import HomeAbout from './HomeAbout';
import InfiniteSlider from './InfiniteSlider';
import WorksCardsSection from './WorksCardsSection';
import Works from './Works';
import Contact from './Contact';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css';

const App = () => {
  const [preloadComplete, setPreloadComplete] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Simula il completamento del preloader dopo 5 secondi (modifica secondo necessità)
    setTimeout(() => {
      setPreloadComplete(true);
      setTimeout(() => setShowContent(true), 200);
    }, 1000);
  }, []);

  return (
    <div>
      <Cursor/>
      {!preloadComplete && (
        <Preload
        loadingText={
          <>
            Il portfolio di Guglielmo è <br /> in fase di caricamento
          </>
        }
        visible={!showContent}
        visibilityDuration={1000}
      />
      )}
      {preloadComplete && showContent && (
        <div>
           
          {/* Inserisci gli elementi dopo il completamento del preloader */}
          <Navbar />
          <ParallaxTitle />
          <HomeAbout />
          <InfiniteSlider />
          <Works />
          <WorksCardsSection />
          <Contact/>
        </div>
      )}
    </div>
  );
};

export default App;
