// Navbar.js
import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Drawer from './Drawer'; // Assicurati che il tuo componente Drawer gestisca le transizioni
import './Navbar.css'; // Assicurati che questo file contenga le regole CSS per le transizioni del drawer
import LogoSVG from '../src/img-sito/guglielmo_logo.svg';

const Navbar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <nav className="navbar sticky">
      <div className="container p-0 d-flex w-100 justify-content-center container-navbar">
        <div className="w-100 d-flex justify-content-between row">
          <div className="navbar-brand col-3">
            <Link to="home" spy={true} smooth={true} offset={-70} duration={500}>
              <img src={LogoSVG} alt="Logo" />
            </Link>
          </div>
          <div className="navbar-menu justify-content-end gap-3 col-4 d-none d-lg-flex">
            <Link to="section1" spy={true} smooth={true} offset={-70} duration={500}>
              About
            </Link>
            <Link to="section2" spy={true} smooth={true} offset={-70} duration={500}>
              Works
            </Link>
            <Link to="section3" spy={true} smooth={true} offset={-70} duration={500}>
              Contact
            </Link>
            {/* Aggiungi ulteriori voci del menu secondo le tue esigenze */}
          </div>
          <div className="navbar-toggle d-none" onClick={toggleDrawer}>
            <span>Menu</span> {/* Sostituisci <span> con un'icona se preferisci */}
          </div>
          <Drawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
