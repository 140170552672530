// Drawer.js
import React from 'react';
import './Drawer.css';
import { Link, animateScroll as scroll } from 'react-scroll';

const Drawer = ({ isOpen, closeDrawer }) => {
  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <div className="container d-flex justify-content-center w-100 p-0 drawer-content">
        <div className='row d-flex justify-content-center w-100 p-0'>
          <div className='col-12 p-0'>
          <div className='position-relative'>
          <div className="drawer-close" onClick={closeDrawer}>
          &times;
        </div>
          </div>
        <div className="drawer-menu">
       
            <Link to="section1" spy={true} smooth={true} offset={-70} duration={500}>
              About
            </Link>
            <Link to="section2" spy={true} smooth={true} offset={-70} duration={500}>
              Works
            </Link>
            <Link to="section3" spy={true} smooth={true} offset={-70} duration={500}>
              Contact
            </Link>
         
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
